import React from 'react'

const ErrorPageHandler = () => {
  return (
    <div>
      <center>404 Page Not Found</center>
    </div>
  )
}

export default ErrorPageHandler
